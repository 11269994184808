<template>
  <div class="group">
    <Header>
      <i class="me-icon-back" slot="left-one" @click="$router.go(-1)"></i>
      <i class="me-icon-group" slot="center"></i>
      <i class="me-icon-share" slot="right-one" @click="handlerChangeStatus"></i>
    </Header>
    <div class="me-group-detail">
      <Author class="me-group-detail__author" :item="author" />
      <div class="me-group-detail__header">
        <div class="me-group-detail__header--title">{{ info.group_name }}</div>
        <Thumb
          class="me-group-detail__header--bg"
          :src="info.group_header"
          width="100%"
          height="180px"
        />
      </div>
      <div class="me-group-detail__members">
        <div class="me-group-detail__members--item" v-for="(item, index) in members" :key="index">
          <!-- provider_id -->
          <Avatar :src="item.profile_photo" width="50px" height="50px" :id="item.provider_id" />
          <span
            v-html="
              item.name.length > 4 ? item.name.slice(0, 4) + '...' : item.name
            "
          ></span>
        </div>
      </div>
      <div class="me-group-detail__tags" v-if="tags.length">
        <van-tag plain type="success" round v-for="(tag, index) in tags" :key="index">{{ tag }}</van-tag>
      </div>
      <div class="me-group-detail__content" v-if="info.group_info" v-html="info.group_info"></div>
      <Zan :id="id" :count="info.like_count" :type="4" />
      <Comments :id="id" :count="info.reviewer_count" type="group"  ref="comments" 
          @handlerReplay="handlerEmitReplay"
          @replayChild="handlerEmitReplayChild"/>
      <Recommend :id="id" type="group" />
    </div>
    <DialogTip :status="joinGroupStatus">
      <div slot="title" class="joinGroup__title">
        <Avatar :src="info.group_header" width="50px" height="50px" />
        <span>{{ info.group_name}}</span>
      </div>
      <van-field
        slot="content"
        v-model="tipContent"
        type="textarea"
        row="3"
        autosize
        maxlength="30"
        :autofocus="joinGroupStatus"
        show-word-limit
      ></van-field>
      <i slot="cancle" class="cancle me-icon-close-requie" @click="handlerClose()"></i>
      <i slot="confirm" class="confirm me-icon-check" @click="handlerJoinGroup()" />
    </DialogTip>
      <div class="replayInput" v-if="showReplay">
      <input v-model="replay" ref="replayInput" id="replayInput" v-focus="showReplay" />
      <van-button
        type="primary"
        icon="success"
        @click="isReplayChild ? handlerReplayChild() : handlerReplayFunc()"
      ></van-button>
    </div>
    <div class="chatBtn" v-else>
      <template v-if="!hasInGroup">
        <div v-if="info.access_pattern!=3" class="btn" @click=" info.access_pattern == '1' ? handlerJoinGroup() : handlerJoinGroupDialog()">
         <van-loading v-if="loading" color="#ffffff" size="34" style="height:auto;padding:0" />
          <i class="me-icon-addGroup" v-else></i>
        </div>
      </template>
      <template v-else>
        <div
          class="btn"
          @click="jumpChatWithPage(info.group_name)"
        >
          <i class="me-icon-chat-dot"></i>
        </div>
        <!-- <div class="btn del" @click="handlerExitGroup()">
          <i class="me-icon-del"></i>
        </div> -->
      </template>
    </div>
    <myShare :show="shareStatus" @close="handlerChangeStatus" :data="shareData" />
  </div>
</template>
<script>
import { Base64 } from "js-base64";
import Header from "@/components/business/h5/header";
import Thumb from "@/components/common/thumb";
import Author from "@/components/business/author";
import Zan from "@/components/business/h5/zan";
import Comments from "@/components/business/h5/comments";
import myShare from "@/components/common/share";
import Recommend from "@/components/business/h5/recommend";
import Avatar from "@/components/common/avatar";
import DialogTip from "@/components/common/dialog-tip";
import { publicDo, joinGroup, exitGroup,replayViews } from "@/api/webApi";
import { formartTags, formartfileIcon, formartImageUrl } from "@/utils/help";

export default {
  name: "group-detial",
  components: {
    Header,
    Thumb,
    Zan,
    Comments,
    Avatar,
    Recommend,
    Author,
    myShare,
    DialogTip
  },
  data() {
    return {
      id: "",
      language: "",
      info: {},
      tags: [],
      members: [],
      author: {},
      shareStatus: false,
      shareData: {},
      joinGroupStatus: false,
      tipContent: "",
      hasInGroup: false,
      curUser: {},
      showReplay:false,
      replay:'',
      isReplayChild:false,
      relpalChildObj:{},
      loading:false,
			groupType: null, // 群类型，1:公开群,2:会员群,3:私密群
    };
  },
  watch: {
    "$route.params.id": {
      handler(val) {
        this.id = val;
        window.location.reload();
      },
      deep: true
    },
    members: {
      handler(newly) {
        this.hasInGroup = newly.some(
          item => item.provider_id === this.curUser.id
        );
      },
      deep: true
    }
  },
  created() {
		this.groupType = this.$route.query.access;
    try{
      if(this.$route.params.id.indexOf("pai_") !== -1){
        this.id = Base64.decode(this.$route.params.id.replace("pai_", ""));
      }else{
        this.id = this.$route.params.id;
      }
    }catch(e){}
    this.language = this.$route.params.language || 1;
    localStorage.setItem("langCode",this.language);
    const userString = localStorage.getItem("userinfo");
    this.curUser = userString ? JSON.parse(userString) : {};
    this.handlerGetGroupInfo();
    this.handlerGetGroupMembers();	
  },
  methods: {
    jumpChatWithPage(name){
      this.$router.push({
        name:'chatWith',
        params:{
          id:"pai_" + Base64.encode(this.id),
          name:name,
          type:'group'
        }
      })
    },
     handlerEmitReplay() {
      this.showReplay = !this.showReplay;
      this.isReplayChild = false;
      this.relpalChildObj = {};
    },
    handlerEmitReplayChild(obj) {
      this.showReplay = !this.showReplay;
      this.isReplayChild = true;
      this.relpalChildObj = obj;
    },
      async handlerReplayFunc() {
      const params = {
        reply: this.replay,
        language: this.language,
        operationId: this.id,
        operation: 4,
        userId: this.$store.state.User.userinfo.id,
        reviewId: ""
      };
      const result = await replayViews(params).catch(e => {});
      if (result && result.data) {
        this.showReplay = false;
        this.replay = "";
        this.$refs.comments.getComments();
      }
    },
      async handlerReplayChild() {
      const loginUser = this.$store.state.User.userinfo;
      if (loginUser && Object.keys(loginUser).length && loginUser.id) {
        const params = {
          ...this.relpalChildObj,
          reply: this.replay,
          userId: loginUser.id,
          token: loginUser.token,
          language: this.language
        };
        const result = await replayViews(params).catch(e => {});
        if (result && result.code == 200) {
          this.handlerEmitReplay();
          this.replay = "";
          this.$refs.comments.getComments();
        }
      } else {
        this.$router.push({
          name: "login",
          query: {
            redirect: this.$route.name,
            ...this.$route.params
          }
        });
      }
    },

    handlerClose() {
      this.joinGroupStatus = false;
      this.loading = false
    },
    async handlerExitGroup() {
      const params = {
        group_id: this.id,
        language: this.language
      };
      const result = await exitGroup(params).catch(e => {});
      this._handlerGroupMemberChange(result);
    },
    async handlerJoinGroup() {
      if (!this.$store.state.User.userinfo.id) {
        this.$router.push({
          name: "login",
          query: { redirect: "group", ...this.$route.params }
        });
        return
      }
      this.loading = true
      const params = {
        groupId: this.id,
        language: this.language,
        userId: this.$store.state.User.userinfo.id,
        type: 0,
        tipContent: this.tipContent
      };
      const result = await joinGroup(params).catch(e => {});
      this.handlerClose();
      this._handlerGroupMemberChange(result);
    },
    _handlerGroupMemberChange(result) {
      if (result && (result.code === 200 || result.code === 1)) {
        this.$toast.success();
        this.handlerGetGroupMembers();
      } else {
        this.$toast.fail();
      }
    },
    handlerJoinGroupDialog() {
      if (!this.$store.state.User.userinfo.id) {
        this.$router.push({
          name: "login",
          query: { redirect: "group", ...this.$route.params }
        });
      } else {
        this.joinGroupStatus = true;
      }
    },
    handlerChangeStatus() {
      this.shareStatus = !this.shareStatus;
    },
    async handlerGetGroupMembers() {
      const params = {
        group_id: this.id,
        table_name_replace: this.language,
        funcId: "hex_helome_query_user_by_group_id"
      };
      const result = await publicDo(params, "/public/data.do").catch(e => {});
      if (result && result.data) this.members = result.data;
      if (this.curUser.id) {
        this.hasInGroup = this.members.some(item => {
          item.provider_id === this.curUser.id;
        });
      }
    },
    async handlerGetGroupInfo() {
      const params = {
        group_id: this.id,
        table_name_replace: this.language,
        funcId: "hex_helome_query_group_by_id"
      };

      const result = await publicDo(params, "/public/data.do").catch(e => {});
      if (result && result.data) {
        this.info = result.data[0];
        this.tags = formartTags(this.info.group_tag);
        this.shareData = {
          title: this.info.group_name,
          desc: this.info.group_info,
          url: `${window.location.href}`
        };
        this.handlerGetGroupOwnerInfo(this.info.group_owner);
      }
    },
    async handlerGetGroupOwnerInfo(id) {
      const params = {
        provider_id: id,
        table_name_replace: this.language,
        funcId: "hex_helome_getProfileInfoFunction"
      };
      const result = await publicDo(params, "/public/data.do").catch(e => {});
      if (result && result.data) {
        const userinfo = result.data[0];
        this.author = {
          name: userinfo.name,
          id: userinfo.provider_id,
          time: userinfo.join_time,
          avatar: userinfo.profile_photo,
          country: userinfo.country
        };
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';
.replayInput {
  width: 100%;
  padding: 0.5rem 0;
  background: #f5f5f5;
  position: fixed;
  border: 0;
  left: 0;
  bottom: 0px;
  display: flex;
  flex-flow: row;

  input {
    border: 1px solid #f5f5f5;
    border-radius: 3rem;
    min-height: 40px;
    margin: 0 0.5rem 0 0.9rem;
    text-indent: 0.6rem;
    flex: 1;
  }

  button {
    height: 40px;
    line-height: 40px;
    margin-right: 0.9rem;
    border-radius: 5rem;
    min-width: 60px;
  }
}
.chatBtn {
  background: #f5f5f5;
  color: #ffffff;
  font-size: 22px;
  position: fixed;
  width: 100%;
  padding: 0.5rem 0;
  bottom: 0;
  display: flex;
  flex-flow: row;

  .btn {
    flex: 1;
    margin: 0 0.5rem;
    background: #33cc66;
    border-radius: 3rem;
    padding: 0.4rem;
    max-width: 90%;
    cursor: pointer;
    text-align: center;

    &.del {
      background: #ffffff;
      border: 1px solid #bbbbbb;
      color: #bbbbbb;
      box-sizing: border-box;
    }

    i {
      font-size: 26px;
      &.me-icon-addGroup{
        font-size:30px;
      }
    }
  }
}

+b(group) {
  background: #ffffff;
}

+b(me-group-detail) {
  margin: 54px 0;

  +e(author) {
    padding: 0.5rem 0.9rem;
    background: #f5f5f5;
  }

  +e(header) {
    position: relative;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    height: 180px;
    z-index:1

    +m(bg) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
    }

    +m(title) {
      z-index: 10;
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
      padding: 0 0.9rem;
      text-shadow: 0.03rem 0.05rem 0.05rem #000;
    }
  }

  +e(members) {
    display: flex;
    flex-flow: row;
    padding: 0.9rem;
    flex-wrap: wrap;

    // overflow-x: scroll;
    +m(item) {
      display: flex;
      flex-flow: column;
      align-items: center;
      width: 20%;
      margin: 0.8rem 0;

      &:nth-child(5n) {
        margin-right: 0;
      }

      &:nth-child(1n) {
        margin-left: 0;
      }

      span {
        font-size: 12px;
        text-align: center;
        margin-top: 0.25rem;
      }
    }
  }

  +e(tags) {
    display: flex;
    flex-flow: row;
    padding: 0.9rem;

    span {
      margin: 0 0.25rem 0.25rem 0;
    }
  }

  +e(content) {
    font-size: 14px;
    color: #666666;
    line-height: 22px;
    text-align: left;
    padding: 0 0.9rem;
  }
}
</style>
