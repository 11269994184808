var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("van-overlay", { attrs: { show: _vm.show } }, [
    _c(
      "div",
      { staticClass: "me-dialog-tipBox" },
      [
        _vm._t("title"),
        _c(
          "div",
          { staticClass: "me-dialog-tipBox__content" },
          [_vm._t("content")],
          2
        ),
        _c(
          "div",
          { staticClass: "me-dialog-tipBox__footer" },
          [_vm._t("cancle"), _vm._t("confirm")],
          2
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }