var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "group" },
    [
      _c("Header", [
        _c("i", {
          staticClass: "me-icon-back",
          attrs: { slot: "left-one" },
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          },
          slot: "left-one"
        }),
        _c("i", {
          staticClass: "me-icon-group",
          attrs: { slot: "center" },
          slot: "center"
        }),
        _c("i", {
          staticClass: "me-icon-share",
          attrs: { slot: "right-one" },
          on: { click: _vm.handlerChangeStatus },
          slot: "right-one"
        })
      ]),
      _c(
        "div",
        { staticClass: "me-group-detail" },
        [
          _c("Author", {
            staticClass: "me-group-detail__author",
            attrs: { item: _vm.author }
          }),
          _c(
            "div",
            { staticClass: "me-group-detail__header" },
            [
              _c("div", { staticClass: "me-group-detail__header--title" }, [
                _vm._v(_vm._s(_vm.info.group_name))
              ]),
              _c("Thumb", {
                staticClass: "me-group-detail__header--bg",
                attrs: {
                  src: _vm.info.group_header,
                  width: "100%",
                  height: "180px"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "me-group-detail__members" },
            _vm._l(_vm.members, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "me-group-detail__members--item" },
                [
                  _c("Avatar", {
                    attrs: {
                      src: item.profile_photo,
                      width: "50px",
                      height: "50px",
                      id: item.provider_id
                    }
                  }),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        item.name.length > 4
                          ? item.name.slice(0, 4) + "..."
                          : item.name
                      )
                    }
                  })
                ],
                1
              )
            }),
            0
          ),
          _vm.tags.length
            ? _c(
                "div",
                { staticClass: "me-group-detail__tags" },
                _vm._l(_vm.tags, function(tag, index) {
                  return _c(
                    "van-tag",
                    {
                      key: index,
                      attrs: { plain: "", type: "success", round: "" }
                    },
                    [_vm._v(_vm._s(tag))]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.info.group_info
            ? _c("div", {
                staticClass: "me-group-detail__content",
                domProps: { innerHTML: _vm._s(_vm.info.group_info) }
              })
            : _vm._e(),
          _c("Zan", {
            attrs: { id: _vm.id, count: _vm.info.like_count, type: 4 }
          }),
          _c("Comments", {
            ref: "comments",
            attrs: {
              id: _vm.id,
              count: _vm.info.reviewer_count,
              type: "group"
            },
            on: {
              handlerReplay: _vm.handlerEmitReplay,
              replayChild: _vm.handlerEmitReplayChild
            }
          }),
          _c("Recommend", { attrs: { id: _vm.id, type: "group" } })
        ],
        1
      ),
      _c(
        "DialogTip",
        { attrs: { status: _vm.joinGroupStatus } },
        [
          _c(
            "div",
            {
              staticClass: "joinGroup__title",
              attrs: { slot: "title" },
              slot: "title"
            },
            [
              _c("Avatar", {
                attrs: {
                  src: _vm.info.group_header,
                  width: "50px",
                  height: "50px"
                }
              }),
              _c("span", [_vm._v(_vm._s(_vm.info.group_name))])
            ],
            1
          ),
          _c("van-field", {
            attrs: {
              slot: "content",
              type: "textarea",
              row: "3",
              autosize: "",
              maxlength: "30",
              autofocus: _vm.joinGroupStatus,
              "show-word-limit": ""
            },
            slot: "content",
            model: {
              value: _vm.tipContent,
              callback: function($$v) {
                _vm.tipContent = $$v
              },
              expression: "tipContent"
            }
          }),
          _c("i", {
            staticClass: "cancle me-icon-close-requie",
            attrs: { slot: "cancle" },
            on: {
              click: function($event) {
                return _vm.handlerClose()
              }
            },
            slot: "cancle"
          }),
          _c("i", {
            staticClass: "confirm me-icon-check",
            attrs: { slot: "confirm" },
            on: {
              click: function($event) {
                return _vm.handlerJoinGroup()
              }
            },
            slot: "confirm"
          })
        ],
        1
      ),
      _vm.showReplay
        ? _c(
            "div",
            { staticClass: "replayInput" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.replay,
                    expression: "replay"
                  },
                  {
                    name: "focus",
                    rawName: "v-focus",
                    value: _vm.showReplay,
                    expression: "showReplay"
                  }
                ],
                ref: "replayInput",
                attrs: { id: "replayInput" },
                domProps: { value: _vm.replay },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.replay = $event.target.value
                  }
                }
              }),
              _c("van-button", {
                attrs: { type: "primary", icon: "success" },
                on: {
                  click: function($event) {
                    _vm.isReplayChild
                      ? _vm.handlerReplayChild()
                      : _vm.handlerReplayFunc()
                  }
                }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "chatBtn" },
            [
              !_vm.hasInGroup
                ? [
                    _vm.info.access_pattern != 3
                      ? _c(
                          "div",
                          {
                            staticClass: "btn",
                            on: {
                              click: function($event) {
                                _vm.info.access_pattern == "1"
                                  ? _vm.handlerJoinGroup()
                                  : _vm.handlerJoinGroupDialog()
                              }
                            }
                          },
                          [
                            _vm.loading
                              ? _c("van-loading", {
                                  staticStyle: { height: "auto", padding: "0" },
                                  attrs: { color: "#ffffff", size: "34" }
                                })
                              : _c("i", { staticClass: "me-icon-addGroup" })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : [
                    _c(
                      "div",
                      {
                        staticClass: "btn",
                        on: {
                          click: function($event) {
                            return _vm.jumpChatWithPage(_vm.info.group_name)
                          }
                        }
                      },
                      [_c("i", { staticClass: "me-icon-chat-dot" })]
                    )
                  ]
            ],
            2
          ),
      _c("myShare", {
        attrs: { show: _vm.shareStatus, data: _vm.shareData },
        on: { close: _vm.handlerChangeStatus }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }