<template>
  <van-overlay :show="show">
    <div class="me-dialog-tipBox">
      <slot name="title"></slot>
     <div class="me-dialog-tipBox__content">
          <slot name="content"></slot>
     </div>
     <div class="me-dialog-tipBox__footer">
          <slot name="cancle"></slot>
          <slot name="confirm"></slot>
     </div>
    </div>
  </van-overlay>
</template>
<script>
export default {
  props: {
    status: {
      type: Boolean,
      default: false
    }
  },
  data(){
      return {
          show:false
      }
  },
  watch: {
    status:{
        handler(newly){
            this.show = newly
        }
    }
  },
  created(){
      this.show = this.status
  },
  methods:{
      close(event){
          event.stopPropagation();
          
          this.show = false
          this.$emit('close')
      }
  }
};
</script>
<style lang="stylus" >
@import '~styles/utils/index';

+b(van-overlay) {
  display: flex;
  align-items: center;
  justify-content: center;
  // z-index: 3001 !important;
}

+b(me-dialog-tipBox) {
//   margin: 0.9rem;
  border-radius: 0.9rem;
//   max-width 96%;
    padding .5rem ;
  display: flex;
  flex-flow: column;
  background: #ffffff;
  overflow hidden
  width 92%;
  margin 0 auto;
  +e(content){
    .van-cell {
        .van-cell__value {
        .van-field__body {
            textarea {
            background: #f5f5f5;
            padding: 0.5rem;
            }
        }
        }
    }
    .sure{
      text-align center;
      padding 2rem;
      i{
        font-size 40px;
        color #666666
        
      }
      }
  }
  +e(footer){
      display flex;
      flex-flow row;
      padding .9rem;
     
      i{
           padding: .5rem 0;
          flex 1;
          text-align center;
          font-size 24px;
        border-radius 5rem;    
            &.cancle{
                margin-right .5rem
                background #f5f5f5;
            }
          &.confirm{
                      
              background #33cc66;
              color #ffffff
        }
      }
  }
}
</style>